import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactUsContent from '../components/ContactUs/ContactUs'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { CONTACT_US as CONTACT_US_LINK } from '../components/internal-links'
import { formatForBigQuery, isMobile } from '../formatForBigQuery'
import { generateContactSchema } from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Contact Us',
    url: CONTACT_US_LINK,
  },
]

const ContactUs: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
    }
  `)
  useEffect(() => {
    try {
      window?.rudderanalytics?.track?.('ContactUsPageViewed', {
        event_params: formatForBigQuery({
          device: isMobile() ? 'Mobile' : 'Desktop',
        }),
      })
    } catch (er) {
      console.error(er)
    }
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Contact Us: Send Us Your Queries and Get Quick Resolution | Mega"
        description="Send us your queries or drop in a line to Say Hi. Whatever be your message, we are listening.  Reachout to us via the form or through our social media handle!"
        contactSchema={generateContactSchema({ data })}
      />
      <ContactUsContent />
    </Layout>
  )
}

export default ContactUs
